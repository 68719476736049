import { Navigate } from 'react-router-dom';

import { useUserContext } from 'App/context';

import { RoutePaths } from 'impactApp/routes/routePaths';
import { useCookies } from 'react-cookie';
import { appConfig } from 'App/appConfig/appConfig';
import { Config } from 'App/config';
import { useEffect } from 'react';
import { generateCodeVerifier } from 'App/utils';
import { useSessionData } from 'impactApp/modules/LoadingPage/useSessionData';

type PrivateRouteProps = {
  element: JSX.Element;
};

export const PrivateRoute = ({ element }: PrivateRouteProps) => {
  const queryParams = new URLSearchParams(window.location.search);

  const code = queryParams.get('code') || '';
  const state = queryParams.get('state') || '';
  const codeVerifier = sessionStorage.getItem('codeVerifier') || '';

  useEffect(() => {
    if (code) return;
    const tempCodeVerifier = generateCodeVerifier();
    sessionStorage.setItem('codeVerifier', tempCodeVerifier);
  }, [code]);

  useSessionData(code, state, codeVerifier);

  const [{ isLoggedIn }] = useUserContext();
  const { REACT_APP_ACCESS_TOKEN_COOKIE_NAME } = appConfig;
  const [cookies] = useCookies();
  const { REACT_APP_MOCK_API } = Config.getAll();
  if (isLoggedIn || cookies[REACT_APP_ACCESS_TOKEN_COOKIE_NAME] || REACT_APP_MOCK_API || code) return element;
  return <Navigate to={RoutePaths.SSO_PAGE} />;
};
