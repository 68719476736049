import { BrowserRouter } from 'react-router-dom';

import { httpClient, httpClientOauth, QueryProvider } from '@ecolytiq/js-data-provider';

import { QueryClientProvider, QueryClient } from '@tanstack/react-query';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { LocalizationProvider } from '@mui/x-date-pickers';

import { Config } from 'App/config';

import { AppsSchema, SingleApp, THEME_OPTION } from './types';

import { goldTheme, privateTheme, theme, ThemeProvider } from './style';

import { workerService } from './test/api';

import { DepsProvider, ThemeOptionProvider, UserProvider } from './context';

import { AutoLogout } from './components';

import '../App/i18n';

type AppProps = { readonly apps: AppsSchema };

const queryClient = new QueryClient();

const renderApp = (App: SingleApp) => <App.Component key={App.id} />;

export const App = ({ apps }: AppProps) => {
  const {
    REACT_APP_MOCK_API: isMockEnvEnabled,
    REACT_APP_ECOLYTIQ_API_GRAPHQL_URL,
    REACT_APP_ECOLYTIQ_API_AUTH_URL,
  } = Config.getAll();
  const apiHost = isMockEnvEnabled === 'true' ? '' : REACT_APP_ECOLYTIQ_API_GRAPHQL_URL;
  const apiAuthHost = isMockEnvEnabled === 'true' ? '' : REACT_APP_ECOLYTIQ_API_AUTH_URL;
  const httpClientService = httpClient(apiHost);
  const httpClientServiceOAuth = httpClientOauth(apiAuthHost);
  let currentTheme = theme;

  const queryParams = new URLSearchParams(window.location.search);
  const themeQueryParam = queryParams.get('theme') || localStorage.getItem('theme') || THEME_OPTION.NEO;
  if (themeQueryParam === THEME_OPTION.GOLD) {
    currentTheme = goldTheme;
  } else if (themeQueryParam === THEME_OPTION.PRIVATE) {
    currentTheme = privateTheme;
  }
  localStorage.setItem('theme', themeQueryParam);

  return (
    <DepsProvider
      httpClientServiceOAuth={httpClientServiceOAuth}
      httpClientService={httpClientService}
      workerService={workerService}
    >
      <UserProvider>
        <QueryProvider>
          <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={currentTheme}>
              <ThemeOptionProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <BrowserRouter>
                    {apps.map(renderApp)}
                    <AutoLogout />
                  </BrowserRouter>
                </LocalizationProvider>
              </ThemeOptionProvider>
            </ThemeProvider>
          </QueryClientProvider>
        </QueryProvider>
      </UserProvider>
    </DepsProvider>
  );
};
