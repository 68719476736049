import { appConfig } from 'App/appConfig/appConfig';
import { Config } from 'App/config';
import axios, { AxiosInstance } from 'axios';
import { useMemo } from 'react';
import { useCookies } from 'react-cookie';

import { useMutation, useQuery } from '@tanstack/react-query';
import { jwtDecode, JwtPayload } from 'jwt-decode';

export const LANGUAGE_CODES = ['en', 'ar'] as const;
export type LanguageCode = (typeof LANGUAGE_CODES)[number];

const useAx = () => {
  const { REACT_APP_ECOLYTIQ_API_GRAPHQL_URL } = Config.getAll();
  const { REACT_APP_ACCESS_TOKEN_COOKIE_NAME } = appConfig;

  const [cookies] = useCookies();
  const accessToken = cookies[REACT_APP_ACCESS_TOKEN_COOKIE_NAME];

  const ax = useMemo(
    () =>
      axios.create({
        baseURL: REACT_APP_ECOLYTIQ_API_GRAPHQL_URL,
        headers: { Authorization: `Bearer ${accessToken}` },
      }),
    [REACT_APP_ECOLYTIQ_API_GRAPHQL_URL, accessToken]
  );

  return ax;
};

const FALLBACK_ACCOUNT_ID = '00000000-0000-0000-0000-000000000000';
const useAccountId = () => {
  const { REACT_APP_ACCESS_TOKEN_COOKIE_NAME } = appConfig;
  const [cookies] = useCookies();
  const accessToken: string | undefined = cookies[REACT_APP_ACCESS_TOKEN_COOKIE_NAME];
  if (!accessToken) return FALLBACK_ACCOUNT_ID;
  try {
    const jwt: JwtPayload & { user_name?: string } = jwtDecode(accessToken);
    return jwt.user_name ?? FALLBACK_ACCOUNT_ID;
  } catch {}
  return FALLBACK_ACCOUNT_ID;
};

export type TotalStatisticsResponse = { intervals: TotalStatisticInterval[] };
type TotalStatisticInterval = {
  co2_footprint_in_g: number;
  co2_credits_in_g: number;
};
type TotalStatisticsParams = { from?: string; to?: string };

const fetchTotalStatistics = async (
  ax: AxiosInstance,
  { accountId, from, to }: TotalStatisticsParams & { accountId: string }
) => {
  const { data, status } = await ax.get<TotalStatisticsResponse>(`/statistic/v1/accounts/${accountId}/footprints`, {
    params: { from, to, interval: 'TOTAL' },
  });
  if (status < 200 || status >= 300) throw new Error('Failed to fetch total statistics');
  return data;
};
export const useTotalStatistics = ({ from, to }: TotalStatisticsParams) => {
  const ax = useAx();
  const accountId = useAccountId();
  return useQuery({
    queryKey: ['total-statistics', accountId, from, to],
    queryFn: () => fetchTotalStatistics(ax, { accountId, from, to }),
  });
};

export type MonthStatisticsResponse = { intervals: MonthStatisticInterval[] };
type MonthStatisticInterval = {
  co2_footprint_in_g: number;
  co2_credits_in_g: number;
  year: number;
  month: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
};
type MonthStatisticsParams = { from?: string; to?: string };

const fetchMonthStatistics = async (
  ax: AxiosInstance,
  { accountId, from, to }: MonthStatisticsParams & { accountId: string }
) => {
  const { data, status } = await ax.get<MonthStatisticsResponse>(`/statistic/v1/accounts/${accountId}/footprints`, {
    params: { from, to, interval: 'MONTH' },
  });
  if (status < 200 || status >= 300) throw new Error('Failed to fetch month statistics');
  return data;
};
export const useMonthStatistics = ({ from, to }: MonthStatisticsParams) => {
  const ax = useAx();
  const accountId = useAccountId();
  return useQuery({
    queryKey: ['month-statistics', accountId, from, to],
    queryFn: () => fetchMonthStatistics(ax, { accountId, from, to }),
  });
};

export type CarbonCreditProjectsResponse = { projects: CarbonCreditProject[] };
type CarbonCreditProject = {
  id: string;
  country: string;
  price_per_kg: { value: number; currency_code: string };
  minimum_co2_amount_in_g: number;
  translations: Translation[];
  registry_name: string;
  classification: string;
  images: ProjectImage[];
  seller: { name: string; logo_url: string };
};
type Translation = {
  language_code: LanguageCode;
  title: string;
  description: string;
  sustainable_development_goals: { id: string; description: string }[];
  description_long: string;
  category: string;
};
type ProjectImage = { image_url: string };

const fetchCarbonCreditProjects = async (ax: AxiosInstance) => {
  const { data, status } = await ax.get<CarbonCreditProjectsResponse>('/action/v2/carbon-credits/projects');
  if (status < 200 || status >= 300) throw new Error('Failed to fetch carbon credit projects');
  return data;
};
export const useCarbonCreditProjects = () => {
  const ax = useAx();
  return useQuery({
    queryKey: ['carbon-credit-projects'],
    queryFn: () => fetchCarbonCreditProjects(ax),
  });
};

type CarbonCreditOrderPayload = {
  account_id: string;
  co2_amount_in_g: number;
  purchase_date: string;
  attribution_month?: string;
  language?: LanguageCode;
};
export type CarbonCreditOrderResponse = {
  checkout_url: string;
  order_id: string;
};
type CarbonCreditOrderParams = { projectId: string; payload: Omit<CarbonCreditOrderPayload, 'account_id'> };

const fetchCarbonCreditOrder = async (
  ax: AxiosInstance,
  { projectId, payload }: CarbonCreditOrderParams & { payload: CarbonCreditOrderPayload }
) => {
  const { data, status } = await ax.post<CarbonCreditOrderResponse>(
    `/action/v2/carbon-credits/projects/${projectId}/order`,
    payload
  );
  if (status < 200 || status >= 300) throw new Error('Failed to fetch carbon credit order');
  return data;
};
export const useLazyCarbonCreditOrder = () => {
  const ax = useAx();
  const accountId = useAccountId();

  return useMutation(({ projectId, payload }: CarbonCreditOrderParams) =>
    fetchCarbonCreditOrder(ax, { projectId, payload: { account_id: accountId, ...payload } })
  );
};

export type CarbonCreditOrdersResponse = { orders: CarbonCreditOrder[] };
type CarbonCreditOrder = {
  account_id: string;
  order_id: string;
  project_id: string;
  price: number;
  currency: string;
  certificate_url: string;
  attribution_month: string;
  purchase_date: string;
  co2_amount_in_g: number;
  project_title: string;
  seller: { name: string; logo_url: string };
};

type CarbonCreditOrdersParams = { language?: LanguageCode };

const fetchCarbonCreditOrders = async (
  ax: AxiosInstance,
  { accountId, language }: CarbonCreditOrdersParams & { accountId: string }
) => {
  const { data, status } = await ax.get<CarbonCreditOrdersResponse>(
    `/action/v2/account/${accountId}/carbon-credits/orders`,
    { params: { language } }
  );
  if (status < 200 || status >= 300) throw new Error('Failed to fetch carbon credit orders');
  return data;
};
export const useCarbonCreditOrders = ({ language }: CarbonCreditOrdersParams) => {
  const ax = useAx();
  const accountId = useAccountId();
  return useQuery({
    queryKey: ['carbon-credit-orders', accountId, language],
    queryFn: () => fetchCarbonCreditOrders(ax, { accountId, language }),
  });
};
