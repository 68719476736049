import { BASIC_GRADIENT_BACKGROUND_HEIGHT, Layout, Modal } from 'App/components';
import { Button, GradientHeader, Header } from 'components';

import { Navigate, useParams } from 'react-router-dom';

import { RoutePaths } from 'impactApp/routes/routePaths';

import { useTranslation } from 'react-i18next';

import { useState } from 'react';

import { LANGUAGE_CODES, LanguageCode, useCarbonCreditProjects, useLazyCarbonCreditOrder } from 'impactApp/api';

import { EcoActionProjectPageStyles } from './EcoActionProjectPage.styles';

import { ProjectInfo } from './submodules/ProjectInfo';
import { OffsetInfo } from './submodules/OffsetInfo';
import { TermsOverlay } from './submodules/TermsOverlay';

const findTranslation = <T extends { language_code: string }>(translations: T[], lang: string): T | undefined => {
  return translations.find((translation) => translation.language_code === lang);
};

export const EcoActionProjectPage = () => {
  const { t, i18n } = useTranslation();
  const [isTermsVisible, setIsTermsVisible] = useState(false);
  const [isNotEnoughModalVisible, setIsNotEnoughModalVisible] = useState(false);
  const [isOrderErrorModalVisible, setIsOrderErrorModalVisible] = useState(false);

  const { id: projectId } = useParams<{ id: string }>();
  const { data, isLoading } = useCarbonCreditProjects();
  const [amount, setAmount] = useState(0);

  const project = data?.projects.find((p) => p.id === projectId);

  const translation =
    project && (findTranslation(project.translations, i18n.language) || findTranslation(project.translations, 'en'));
  const minAmount = Math.ceil(((project?.minimum_co2_amount_in_g ?? 0) / 1000) * (project?.price_per_kg.value ?? 0));

  const { mutate: order } = useLazyCarbonCreditOrder();
  const initOrder = async () => {
    if (!project) return;
    await order(
      {
        projectId: project.id,
        payload: {
          co2_amount_in_g: (amount / project.price_per_kg.value) * 1000,
          purchase_date: new Date().toISOString().substring(0, 10),
          language: LANGUAGE_CODES.includes(i18n.language as LanguageCode) ? (i18n.language as LanguageCode) : 'en',
        },
      },
      {
        onError: () => {
          setIsTermsVisible(false);
          setIsOrderErrorModalVisible(true);
        },
        onSuccess: (resp) => {
          if (resp?.checkout_url) window.location.href = resp.checkout_url;
        },
      }
    );
  };

  if (!project && !isLoading) return <Navigate to={RoutePaths.ECO_ACTION_PROJECT} />;
  return (
    <>
      {isTermsVisible && <TermsOverlay onAccept={() => initOrder()} onReject={() => setIsTermsVisible(false)} />}

      <Modal
        slug='not-enough-credits'
        isOpen={isNotEnoughModalVisible}
        onClose={() => setIsNotEnoughModalVisible(false)}
        content={t('action:detail:error:notEnoughCredits', { minAmount })}
        successButtonText={t('common:ok')}
      />
      <Modal
        slug='order-error'
        isOpen={isOrderErrorModalVisible}
        onClose={() => setIsOrderErrorModalVisible(false)}
        content={t('action:detail:error:order')}
        successButtonText={t('common:ok')}
      />
      <Layout
        bgHeight={BASIC_GRADIENT_BACKGROUND_HEIGHT}
        bgColor='dark'
        hideContainer
        header={<Header title={t('action:detail:header:title') ?? ''} transparent showDesktopNavigation />}
      >
        <GradientHeader>&nbsp;</GradientHeader>
        <EcoActionProjectPageStyles.ProjectHeader>
          <EcoActionProjectPageStyles.ImageSlider>
            {project?.images.map((image) => (
              <EcoActionProjectPageStyles.Image key={image.image_url} src={image.image_url} />
            ))}
          </EcoActionProjectPageStyles.ImageSlider>
          <EcoActionProjectPageStyles.TitleWrapper>
            <EcoActionProjectPageStyles.Title variant='subtitle1'>
              {translation?.title}
            </EcoActionProjectPageStyles.Title>
          </EcoActionProjectPageStyles.TitleWrapper>
        </EcoActionProjectPageStyles.ProjectHeader>
        <EcoActionProjectPageStyles.ContentWrapper>
          {
            <ProjectInfo
              description={translation?.description_long ?? ''}
              sustainabilityGoals={
                translation?.sustainable_development_goals.map((goal) => ({
                  goalId: goal.id,
                  description: goal.description,
                })) ?? []
              }
            />
          }
          {/* Currently we only use the currency AED */}
          <OffsetInfo pricePerTon={(project?.price_per_kg.value ?? 0) * 1000} onAmountChange={setAmount} />
        </EcoActionProjectPageStyles.ContentWrapper>
        <EcoActionProjectPageStyles.ContinueWrapper>
          <Button
            isFullWidth
            text={t('common:continue')}
            onClick={() => {
              if (amount < minAmount) setIsNotEnoughModalVisible(true);
              else setIsTermsVisible(true);
            }}
          />
        </EcoActionProjectPageStyles.ContinueWrapper>
      </Layout>
    </>
  );
};
