import { Layout } from 'App/components';
import { CircularLoading } from 'components';

import { LoadingPageStyles } from './LoadingPage.styles';

export const LoadingPage = () => {
  return (
    <Layout bgColor='dark' isFullHeight>
      <LoadingPageStyles.Wrapper>
        <CircularLoading />
      </LoadingPageStyles.Wrapper>
    </Layout>
  );
};
