import { InputAdornment, styled, TextField, Typography } from '@mui/material';

const Wrapper = styled('div')(({ theme }) => ({
  background: theme.palette.common.white,
  paddingLeft: 16,
  paddingRight: 16,
  paddingTop: 24,
  paddingBottom: 24,
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
}));

const Title = styled(Typography)({});
const Description = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

const AmountTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    borderRadius: 6,
    '& input': { paddingTop: 10, paddingBottom: 10 },
  },
  '& .MuiInputBase-input': { fontSize: '0.8rem' },
});

const AmountTextFieldAdornment = styled(InputAdornment)({
  '& .MuiTypography-root': { fontSize: '0.75rem' },
});

const Info = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 24,
  paddingTop: 12,
});

const InfoRow = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const InfoTitle = styled(Typography)({});
const InfoValue = styled(Typography)({});

export const OffsetInfoStyles = {
  Wrapper,
  Title,
  Description,
  AmountTextField,
  AmountTextFieldAdornment,
  Info,
  InfoRow,
  InfoTitle,
  InfoValue,
};
