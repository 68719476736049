import { motion } from 'framer-motion';
import { animationList } from 'App/style/animations';
import { List, ListHeader, ListItem, ListStyles } from 'components';
import { useTranslation } from 'react-i18next';
import { HighlightedColor } from 'App/types';
import { convertGramsToLargerUnits, formatCurrency, formatFullDate, formatNumber } from 'App/utils';
import i18next from 'i18next';
import { CO2Icon } from 'components/Icons/CO2Icon';
import { CurrencySymbol } from 'App/translations';
import { useState } from 'react';
import { CarbonCreditOrdersResponse, LANGUAGE_CODES, LanguageCode, useCarbonCreditOrders } from 'impactApp/api';

const generateItems = (locale: string, orders: CarbonCreditOrdersResponse['orders'], maxLength: number) => {
  return orders.slice(0, maxLength).map((order) => {
    const date = formatFullDate(order.purchase_date, locale);
    const co2kg = convertGramsToLargerUnits(order.co2_amount_in_g);
    const value = formatCurrency(order.price, order.currency as CurrencySymbol);

    return (
      <motion.div key={order.order_id} animate='visible' initial='hidden' variants={animationList}>
        <ListItem
          appearance={HighlightedColor.ORANGE}
          icon={<CO2Icon />}
          information={date}
          title={order.project_title}
          value={value}
          weightValue={`${formatNumber(Number(co2kg[0]))} ${i18next.t(co2kg[1])}`}
          isAvatarOrange
          hideChevron
        />
        <ListStyles.Divider />
      </motion.div>
    );
  });
};

export const RecentTransactionsList = () => {
  const { t, i18n } = useTranslation();
  const { data } = useCarbonCreditOrders({
    language: LANGUAGE_CODES.includes(i18n.language as LanguageCode) ? (i18n.language as LanguageCode) : 'en',
  });
  const orders = data?.orders || [];

  const [maxLength, setMaxLength] = useState(3);

  const listHeader = (
    <ListHeader position='flex-start' subtitle={''} title={t('action:dashboard:transactionList:title')} />
  );

  return (
    <List
      paddingTop
      isExpanderVisible={orders.length > 3}
      canFetchMore={maxLength < orders.length}
      expandList={() => setMaxLength(orders.length)}
      shortenList={() => setMaxLength(3)}
      listItems={generateItems(i18n.language, orders, maxLength)}
      noDataText={t('common:noData')}
      listHeader={listHeader}
    />
  );
};
