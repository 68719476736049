import { Navigate, RouteObject } from 'react-router-dom';
import { PrivateRoute } from 'App/routes';
import {
  FoodSettingsPage,
  HomePage,
  LandingPage,
  LoadingPage,
  LogoutPage,
  ProfileSettingsPage,
  ReportDetailsPage,
  SSOPage,
  TransactionDetailsEditPage,
  TransactionDetailsPage,
} from 'impactApp/modules';

import { RoutePaths } from 'impactApp/routes/routePaths';

import { EcoActionOnboardingPage } from 'impactApp/modules/EcoActionOnboardingPage/EcoActionOnboardingPage';
import { EcoActionDashboardPage } from 'impactApp/modules/EcoActionDashboardPage/EcoActionDashboardPage';
import { EcoActionProjectPage } from 'impactApp/modules/EcoActionProjectPage/EcoActionProjectPage';
import { EcoActionCheckoutPage } from 'impactApp/modules/EcoActionCheckoutPage/EcoActionCheckoutPage';
import { EcoActionCheckoutSuccessPage } from 'impactApp/modules/EcoActionCheckoutPage/EcoActionCheckoutSuccessPage';
import { EcoActionBanner } from 'impactApp/modules/EcoActionBanner/EcoActionBanner';

export const ROUTES: RouteObject[] = [
  { path: RoutePaths.HOME, element: <PrivateRoute element={<HomePage />} /> },
  { path: RoutePaths.REPORT_DETAILS, element: <PrivateRoute element={<ReportDetailsPage />} /> },
  { path: RoutePaths.TRANSACTION_DETAILS, element: <PrivateRoute element={<TransactionDetailsPage />} /> },
  { path: RoutePaths.TRANSACTION_DETAILS_EDIT, element: <PrivateRoute element={<TransactionDetailsEditPage />} /> },
  { path: RoutePaths.PROFILE_SETTINGS, element: <PrivateRoute element={<ProfileSettingsPage />} /> },
  { path: RoutePaths.FOOD_SETTINGS, element: <PrivateRoute element={<FoodSettingsPage />} /> },
  { path: RoutePaths.LOGOUT_PAGE, element: <LogoutPage /> },
  { path: RoutePaths.SSO_PAGE, element: <SSOPage /> },
  { path: RoutePaths.LOADING_PAGE, element: <PrivateRoute element={<LoadingPage />} /> },
  { path: RoutePaths.LANDING_PAGE, element: <PrivateRoute element={<LandingPage />} /> },
  { path: RoutePaths.ECO_ACTION, element: <PrivateRoute element={<EcoActionDashboardPage />} /> },
  { path: RoutePaths.ECO_ACTION_ONBOARDING, element: <PrivateRoute element={<EcoActionOnboardingPage />} /> },
  { path: RoutePaths.ECO_ACTION_PROJECT, element: <PrivateRoute element={<EcoActionProjectPage />} /> },
  { path: RoutePaths.ECO_ACTION_CHECKOUT, element: <PrivateRoute element={<EcoActionCheckoutPage />} /> },
  { path: RoutePaths.ECO_ACTION_BANNER, element: <PrivateRoute element={<EcoActionBanner />} /> },
  {
    path: RoutePaths.ECO_ACTION_CHECKOUT_SUCCESS,
    element: <PrivateRoute element={<EcoActionCheckoutSuccessPage />} />,
  },
  { path: RoutePaths.ECO_ACTION_CHECKOUT_ERROR, element: <Navigate to={RoutePaths.ECO_ACTION} /> },

  { path: '*', element: <Navigate to={RoutePaths.HOME} /> },
];
